import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsFeedbackFeatureModule } from '@design-system/feature/feedback';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { BASE_PATH } from './shared/generated';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';

@NgModule({
  imports: [
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),

    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
    DsFeedbackFeatureModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  providers: [
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BASE_PATH,
      useFactory: () =>
        environment.palfinger_app_gateway + '/claim-on-suppliers',
    },
    // User Profile
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    //help pages
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/ms-wiki',
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/Help pages/Claim on Suppliers',
          projectName: 'Palfinger.Paldesk',
          wikiName: 'Palfinger.Paldesk.wiki',
          appTitle: 'cos.claim_on_suppliers',
        }) as HelpPagesConfig,
    },
  ],
})
export class CoreModule {}
